import React from 'react';
import { Avatar, Menu, Popover } from 'antd';
import { BiChevronDown } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import handleUserLogin from '../pages/SignIn/api';
import { getTokenDetails } from '../../utils/getTokenDetails';

interface UserProfileProps {
  collapsed: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ collapsed }) => {
  const location = useLocation();
  const selectedKeys = location?.pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const { userLogOut, isLoading } = handleUserLogin();
  const { tokenData } = getTokenDetails();

  const userMenuOptions = (
    <Menu
      mode="inline"
      defaultSelectedKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      style={{ borderRadius: 12, padding: '8px 0px 8px 8px' }}>
      <Menu.Item key="change-password" title={'Change Password'} className="!py-0 !pl-2 !m-0 ">
        <Link to="/change-password">Change Password</Link>
      </Menu.Item>
      <Menu.Item key="home" title={'Logout'} className="!py-0 !pl-2 !m-0">
        <button
          className="w-full text-left"
          style={{ cursor: isLoading ? 'progress' : 'pointer' }}
          onClick={userLogOut}>
          Logout
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="p-6 flex justify-start items-center">
      <Popover
        overlayInnerStyle={{ padding: 0 }}
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        className="flex justify-center items-center cursor-pointer">
        <Avatar src={'https://via.placeholder.com/150'} alt="" size={40} />
        {!collapsed && (
          <span
            className="flex"
            style={{
              marginLeft: 10,
              color: '#fff'
            }}
            title={tokenData?.fullName}>
            {/* NOTE: change the name as per the user */}

            {tokenData?.fullName
              ? tokenData?.fullName?.length > 10
                ? `${tokenData?.fullName?.slice(0, 10)}...`
                : tokenData?.fullName
              : 'User'}

            <BiChevronDown color="#fff" style={{ marginLeft: 2, marginTop: 5 }} />
          </span>
        )}
      </Popover>
    </div>
  );
};

export default UserProfile;
