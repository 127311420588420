import { Dispatch, SetStateAction } from 'react';
import { IUserList } from '../../../../interfaces/users';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';

export const getUserList = async ({
  setLoading,
  setUserList,
  pageNumber = 1,
  pageLimit = 20,
  callBackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setUserList: Dispatch<SetStateAction<IUserList>>;
  pageNumber: number;
  pageLimit: number;
  callBackFunc?: () => void;
}) => {
  let payload = {} as {
    limit: number;
    offset: number;
  };

  if (pageLimit) {
    payload = {
      ...payload,
      offset: pageNumber - 1, // subtract by 1, As per api requirements, page number start from 0.
      limit: pageLimit
    };
  }

  try {
    setLoading(true);
    const response = await httpClient.post('/user/users-list', payload);
    setUserList(response?.data?.data);
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    setUserList({ totalPage: 0, totalUsers: 0, usersList: [] });
  } finally {
    setLoading(false);
  }
};
