import React, { useEffect, useState } from 'react';
import { PaginationProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IUserData, IUserList } from '../../../../interfaces/users';
import { getUserList } from '../api';

const UsersTable = () => {
  const initialUserListData = {
    totalPage: 0,
    totalUsers: 0,
    usersList: []
  };
  const [usersList, setUsersList] = useState<IUserList>(initialUserListData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);

  const handleGetUserList = () => {
    getUserList({
      setLoading: setIsLoading,
      setUserList: setUsersList,
      pageNumber: currentPage,
      pageLimit
    });
  };

  useEffect(() => {
    handleGetUserList();
  }, [pageLimit, currentPage]);

  const tableColumns: ColumnsType<IUserData> = [
    {
      title: 'Name',
      render: (_, user) => (
        <div className="capitalize">{`${user?.first_name} ${user?.last_name}`}</div>
      ),
      width: 300
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => email ?? 'NA',
      width: 300
    },
    {
      title: 'Mobile number',
      dataIndex: 'mobile_no',
      render: (mob) => mob ?? 'NA',
      width: 300
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      render: (company) => company ?? 'NA',
      width: 300
    },
    {
      title: 'Allowed Order',
      dataIndex: 'allowed_order_types',
      render: (type) => (type ? <div className="capitalize">{type}</div> : 'NA'),
      width: 300
    }
  ];

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <>
      <h2 className="text-xl font-normal m-0">Users ({usersList?.totalUsers})</h2>

      <Table
        className="my-4"
        columns={tableColumns}
        loading={isLoading}
        rowKey={'user_id'}
        scroll={{ x: 1600 }}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: usersList?.totalPage ? usersList?.totalPage * pageLimit : 0,
          onChange: onPageChange
        }}
        dataSource={usersList?.usersList}
      />
    </>
  );
};

export default UsersTable;
