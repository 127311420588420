import { Modal } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { IOrderCancellationResponse } from '../../../../../../../interfaces/cancelOrder';
import moment from 'moment';

interface ICancellationStatusModal {
  cancelStatusDiv: boolean;
  setCancelStatusDiv: Dispatch<SetStateAction<boolean>>;
  cancelStatusData: IOrderCancellationResponse | undefined;
}
const CancellationStatusModal: FC<ICancellationStatusModal> = ({
  cancelStatusDiv,
  setCancelStatusDiv,
  cancelStatusData
}) => {
  return (
    <Modal
      open={cancelStatusDiv}
      onCancel={() => setCancelStatusDiv(false)}
      title={
        <div className="flex items-center gap-2">
          <h2>Cancellation Status</h2>
        </div>
      }
      footer={false}>
      <div className="flex items-center mb-3">
        <p className="w-44 font-semibold text-lg">Cancelled By</p>
        <p className="pr-2">:</p>
        <p>{cancelStatusData?.cancelled_by}</p>
      </div>
      <div className="flex items-center mb-3 mt-4">
        <p className="w-44 font-semibold text-lg">Cancel Status</p>
        <p className="pr-2">:</p>
        <p>{cancelStatusData?.cancel_status}</p>
      </div>
      <div className="flex items-center mb-3">
        <p className="min-w-[190px]  font-semibold text-lg">Cancellation Reason</p>
        <p className="pr-2">:</p>
        <p>{cancelStatusData?.cancel_reason}</p>
      </div>
      <div className="flex items-center mb-3">
        <p className="w-44 font-semibold text-lg">Cancellation Date</p>
        <p className="pr-2">:</p>
        <p>{moment(cancelStatusData?.created_date).format('YYYY-MM-DD, HH:mm:ss')}</p>
      </div>
    </Modal>
  );
};

export default CancellationStatusModal;
