import React, { Fragment, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AuthPage from './AuthPage';
import jwtDecode from 'jwt-decode';

interface AuthRouterProps {
  isAuthenticated: boolean;
}

interface IDecodedToken {
  exp: number;
}

const AuthRouter: React.FC<AuthRouterProps> = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = localStorage.getItem('token');

  const validateToken = () => {
    if (token) {
      const decodedToken: IDecodedToken = jwtDecode(token);
      const currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) navigate('/signin'); // token expired
      else return;
    }
  };

  useEffect(() => {
    if (pathname === '/orders') validateToken();
    if (!isAuthenticated) navigate('/signin');
  }, []);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="signin" replace />} />
      </Routes>
      {isAuthenticated ? <AuthPage isAuthenticated={isAuthenticated} /> : null}
    </Fragment>
  );
};

export default AuthRouter;
