import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { anonymous } from '../config/userRoutes';
import AuthRouter from './AuthRouter';

interface UserRouterProps {
  isAuthenticated: boolean;
}

const UserRouter: React.FC<UserRouterProps> = ({ isAuthenticated }) => {
  return (
    <Fragment>
      <Routes>
        {anonymous.map(({ routePath, Component }) => {
          return <Route key={routePath} path={routePath} element={<Component />}></Route>;
        })}
      </Routes>
      <AuthRouter isAuthenticated={isAuthenticated} />
    </Fragment>
  );
};

export default UserRouter;
