import React, { useState } from 'react';
import ComReportDatePicker from '../ComReportDatePicker';
import OrderStatusDropdown from '../OrderStatusDropdown';
import DeliveryVendor from '../DeliveryVendor';
import { Button, Form } from 'antd';
import { getOrdersReport } from '../api';
import { FaDownload } from 'react-icons/fa';

const OrdersReport = () => {
  const [dateRange, setDateRange] = useState<{
    from: string;
    to: string;
  }>({
    from: '',
    to: ''
  });

  const [error, setError] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [deliveryVendor, setDeliveryVendor] = useState<string>('');
  const [reportLoading, setReportLoading] = useState<boolean>(false);

  const onClickDownload = (fileUrl: string) => {
    window.open(fileUrl, '_blank');
  };

  const handleGetOrder = () => {
    if (Object.values(dateRange)?.filter((val) => val)?.length || deliveryVendor || orderStatus) {
      setError('');
      getOrdersReport({
        from: dateRange?.from,
        to: dateRange?.to,
        orderStatus: orderStatus,
        deliveryVendor: deliveryVendor,
        setLoading: setReportLoading,
        callBackfunc: onClickDownload
      });
    } else {
      setError('Please enter any data.');
    }
  };

  const orderStatusOptions: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'PROCESSING',
      value: 'Processing'
    },
    {
      label: 'SUCCESS',
      value: 'Order Created'
    },
    { label: 'FAILED', value: 'Order Failed' },
    {
      label: 'CANCELLED',
      value: 'Cancelled'
    }
  ];

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">Orders Report</h2>
      <div className="flex flex-col items-center gap-5">
        <div className="p-10 rounded-xl flex flex-col gap-5 primary-box-shadow">
          <Form layout="vertical" onFinish={handleGetOrder}>
            <ComReportDatePicker
              id={'orderReportDate'}
              currentState={dateRange}
              setState={setDateRange}
            />

            <OrderStatusDropdown setOrderStatus={setOrderStatus} options={orderStatusOptions} />

            <DeliveryVendor setDeliveryVendor={setDeliveryVendor} />

            {error ? <p className="m-0 text-center text-red-400">{error}</p> : null}

            <Button
              disabled={reportLoading}
              htmlType="submit"
              loading={reportLoading}
              className="mt-2 primary-button w-full"
              onClick={handleGetOrder}
              icon={<FaDownload />}>
              Download report
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OrdersReport;
