import { Dispatch, SetStateAction } from 'react';
import { SuccessMessage, showMessage } from '../../../../utils/ResponseMessages';
import { IAddUser } from '../../../../interfaces/user';
import { httpClient } from '../../../../utils/apiRequest';

export const addNewUser = async ({
  setLoading,
  payload,
  callBackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: IAddUser;
  callBackFun: () => void;
}) => {
  try {
    setLoading(true);
    await httpClient.post(`/auth/create`, payload);
    SuccessMessage(`Account Created Successfully.`);
    if (callBackFun) {
      callBackFun();
    }
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};
