import React, { Dispatch, SetStateAction } from 'react';
import { Form, Select } from 'antd';

interface Props {
  setOrderStatus: Dispatch<SetStateAction<string>>;
  options: { label: string; value: string }[];
}
const OrderStatusDropdown: React.FC<Props> = ({ setOrderStatus, options }) => {
  const handleStatusChange = (value: string) => {
    setOrderStatus(value);
  };

  return (
    <div className="mb-5 flex items-center">
      <p className="m-0 mr-2 w-[30%] flex justify-between">
        Order Status <span>:</span>
      </p>
      <div className="w-[70%]">
        <Form.Item name="orderStatus" className="m-0 w-full">
          <Select
            showSearch
            allowClear
            placeholder="Select Status"
            onChange={handleStatusChange}
            options={options}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default OrderStatusDropdown;
