import React from 'react';

interface GetStatusProps {
  currStatus?: string;
  PROCESSING?: string;
  SUCCESS?: string;
  FAILED?: string;
  CANCELLED?: string;
  UNKNOWN?: string;
  CHECKED_OUT?: string;
  CUSTOM_LABEL?: string;
}

const GetStatus: React.FC<GetStatusProps> = ({
  currStatus,
  // mention actual key values
  PROCESSING,
  SUCCESS,
  FAILED,
  CANCELLED,
  UNKNOWN = 'Unknown',
  CHECKED_OUT,
  CUSTOM_LABEL
}) => {
  switch (currStatus) {
    case PROCESSING:
      return <p className="text-yellow-400">{CUSTOM_LABEL ? CUSTOM_LABEL : 'PROCESSING'}</p>;
    case SUCCESS:
      return <p className="text-green-400">{CUSTOM_LABEL ? CUSTOM_LABEL : 'SUCCESS'}</p>;
    case FAILED:
      return <p className="text-red-400">{CUSTOM_LABEL ? CUSTOM_LABEL : 'FAILED'}</p>;
    case CANCELLED:
      return <p className="text-grey-400">{CUSTOM_LABEL ? CUSTOM_LABEL : 'CANCELLED'}</p>;
    case CHECKED_OUT:
      return (
        <p className="font-bold text-grey-400">{CUSTOM_LABEL ? CUSTOM_LABEL : 'CHECKED OUT'}</p>
      );
    default:
      return <p className="text-grey-400">{UNKNOWN}</p>;
  }
};

export default GetStatus;
