import React, { useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { FaList, FaTable, FaUser, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import UserProfile from '../Sidebar/UserProfile';
import { useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { CLOUDINARY_URL } from '../../utils/config';
import { getTokenDetails } from '../../utils/getTokenDetails';
import { BiSolidReport } from 'react-icons/bi';

// NOTE: future use : controlled menu items:
// import jwt_decode from 'jwt-decode';
// import { IDecodedToken } from '../../interfaces/decodedToken';
// import UserMenu from '../pages/ManageMenu/api';
// import AddMenu from '../pages/AddMenu';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const { tokenData } = getTokenDetails();

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const location = useLocation();

  const selectedKeys = location?.pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  // NOTE: future use : controlled menu items:
  // const { error, isLoading, getMenuMappedData, getMappedMenu } = UserMenu();
  // const [menuIds, setMenuIds] = useState<string[]>([]);

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type
    } as MenuItem;
  };

  // NOTE: future use : controlled menu items:
  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     const decoded = jwt_decode(token) as IDecodedToken;

  //     if (decoded?.role?.length) {
  //       getMenuMappedData({ department: decoded.department, role: decoded.role[0] });
  //     }
  //   }
  // }, []);
  // useEffect(() => {
  //   const userMenuList = getMappedMenu.MappedList;
  //   const ids: string[] = userMenuList.map((item: { menuId: string }) => item.menuId);
  //   setMenuIds(ids);
  // }, [getMappedMenu]);

  const menuItems: MenuItem[] = [
    getItem(<Link to="/orders">Orders List</Link>, 'orders', <FaTable size={20} />),
    getItem(
      <Link to="/cancelled-orders">Cancelled Orders</Link>,
      'cancellation-orders',
      <FaList size={20} />
    ),
    getItem(
      <Link to="/orders-report">Orders Report</Link>,
      'orders-report',
      <BiSolidReport size={20} />
    ),
    tokenData?.userRole === 0
      ? getItem(<Link to="/admin">Admin</Link>, 'admin', <FaUser size={20} />)
      : null,
    getItem(<Link to="/users">Users</Link>, 'users', <FaUsers size={20} />)

    // NOTE: future use : controlled menu items:
    // getItem(<Link to="/manage-menu">Manage Menu</Link>, 'manage-menu', <FaTable size={20} />),
    // getItem(<Link to="/add-menu">Add Menu Items</Link>, 'add-menu', <FaTable size={20} />),
  ];

  // NOTE: future use : controlled menu items:
  // const filteredMenuItems = menuItems?.filter((item: any) => {
  //   return item.key !== null && menuIds.includes(item.key);
  // });
  const rootSubmenuKeys = ['orders-main']; // add sub menu main keys

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    // eslint-disable-next-line
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onMenuItemSelect: MenuProps['onSelect'] = ({ keyPath }) => {
    const latestOpenKey = keyPath?.pop() as string;
    if (!rootSubmenuKeys?.includes(latestOpenKey)) {
      setOpenKeys([]);
    }
  };
  return (
    <Sider trigger={null} collapsible collapsed={collapsed} width={225}>
      <Header
        style={{
          padding: '10px 10px 10px 15px',
          background: '#002447',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Button
          type="text"
          icon={
            collapsed ? (
              <MenuUnfoldOutlined style={{ color: 'white' }} />
            ) : (
              <MenuFoldOutlined style={{ color: 'white' }} />
            )
          }
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 40,
            height: 40,
            position: 'absolute',
            left: 20
          }}
        />
        {!collapsed && (
          <img
            src={`${CLOUDINARY_URL}/Sahaj/sahaj-powered-coreyo-logo_rwsesz.png`}
            alt="Logo"
            width={70}
            height={30}
          />
        )}
      </Header>

      <UserProfile collapsed={collapsed} />

      <Menu
        theme="dark"
        mode="inline"
        className="sidebar-height !bg-none"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        onSelect={onMenuItemSelect}
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        style={{ background: '#003366' }}
        items={menuItems}

        // NOTE: future use : controlled menu items:
        // items={filteredMenuItems}
      ></Menu>
    </Sider>
  );
};
export default Sidebar;
