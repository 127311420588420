import { Dispatch, SetStateAction } from 'react';
import {
  ICancelOrderPayload,
  IOrderCancellationResponse,
  IOrderCancellationStatus
} from '../../../../../../../interfaces/cancelOrder';
import { SuccessMessage, showMessage } from '../../../../../../../utils/ResponseMessages';
import { httpClient } from '../../../../../../../utils/apiRequest';

export const cancelOrderById = async ({
  payload,
  setLoading,
  callBackFunc
}: {
  payload: ICancelOrderPayload;
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBackFunc?: () => void;
}) => {
  try {
    setLoading(true);
    await httpClient.post('/order-cancellation/cancel', payload);
    SuccessMessage('Order Cancelled Successfully.');
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const orderCancellationStatus = async ({
  orderId,
  setLoading,
  callBackFunc,
  setData
}: {
  orderId: IOrderCancellationStatus;
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBackFunc?: () => void;
  setData: Dispatch<SetStateAction<IOrderCancellationResponse | undefined>>;
}) => {
  try {
    setLoading(true);

    const response = await httpClient.get(`/order-cancellation/status/${orderId}`);
    setData(response?.data?.data);
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    setData(undefined);
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};
