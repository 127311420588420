import React, { FC } from 'react';
import AddUser from './AddUser';

const AdminActions: FC = () => {
  return (
    <div>
      <h2 className="text-xl font-normal m-0">Admin</h2>

      <AddUser />
    </div>
  );
};

export default AdminActions;
