import AdminActions from '../../components/pages/AdminActions';
import CancellationList from '../../components/pages/CancellationList';
import Orders from '../../components/pages/Orders';
import OrderDetails from '../../components/pages/Orders/Partials/OrderDetails';
import OrdersReport from '../../components/pages/Reports/OrdersReport';
import SignIn from '../../components/pages/SignIn';
import ChangePassword from '../../components/pages/SignIn/partials/ChangePassword';
import ForgetPassword from '../../components/pages/SignIn/partials/ForgetPassword';
import Users from '../../components/pages/Users';
import { getTokenDetails } from '../../utils/getTokenDetails';
import PageNotFound from '../components/PageNotFound';

// NOTE: future use : controlled menu items:
// import ManageMenu from '../../components/pages/ManageMenu';
// import AddMenu from '../../components/pages/AddMenu';

const { tokenData } = getTokenDetails();

export const anonymous = [
  {
    routePath: '/signin',
    Component: SignIn
  },
  {
    routePath: '/forget-password',
    Component: ForgetPassword
  }
];

// add new routes here
export let authenticated = [
  {
    routePath: '*',
    Component: PageNotFound
  },
  {
    routePath: '/change-password',
    Component: ChangePassword
  },
  {
    routePath: '/orders',
    Component: Orders
  },
  {
    routePath: '/order-details/:orderId',
    Component: OrderDetails
  },
  {
    routePath: '/users',
    Component: Users
  },
  {
    routePath: '/orders-report',
    Component: OrdersReport
  },
  {
    routePath: '/cancelled-orders',
    Component: CancellationList
  }

  // NOTE: future use : controlled menu items:
  // {
  //   routePath: '/manage-menu',
  //   Component: ManageMenu
  // },
  // {
  //   routePath: '/add-menu',
  //   Component: AddMenu
  // },
];

if (tokenData?.userRole === 0) {
  authenticated = [
    ...authenticated,
    {
      routePath: '/admin',
      Component: AdminActions
    }
  ];
}
