import { Dispatch, SetStateAction } from 'react';
import { showMessage } from '../../../../utils/ResponseMessages';
import { httpClient } from '../../../../utils/apiRequest';
import { API_URL } from '../../../../utils/config';

export const getOrdersReport = async ({
  from,
  to,
  orderStatus,
  deliveryVendor,
  setLoading,
  callBackfunc
}: {
  from: string;
  to: string;
  orderStatus?: string;
  deliveryVendor?: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  callBackfunc: (fileUrl: string) => void;
}) => {
  setLoading(true);
  const queryParams: string[] = [];

  if (from) {
    queryParams.push(`from=${from?.replace(' ', '-')}&to=${to?.replace(' ', '-')}`);
  }
  if (deliveryVendor) {
    queryParams.push(`deliveryVendor=${deliveryVendor}`);
  }
  if (orderStatus) {
    queryParams.push(`orderStatus=${orderStatus}`);
  }

  const queryString = queryParams.join('&');

  try {
    const response = await httpClient.get(`${API_URL}/order/orders-report?${queryString}`);

    if (response?.data?.status === 404) {
      showMessage(response?.data?.message || 'No Orders Found', response?.data?.status);
    } else {
      if (callBackfunc) callBackfunc(response?.data?.file?.presignedUrl);
    }
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};
