import React, { useEffect } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import handleUserLogin from './api';
import { useNavigate } from 'react-router-dom';
import { CLOUDINARY_URL } from '../../../utils/config';
const SignIn: React.FC = () => {
  const { userLogin, isLoading } = handleUserLogin();
  const navigate = useNavigate();

  const onSubmitSignIn = async (formdValues: { email: string; password: string }) => {
    userLogin(formdValues?.email, formdValues?.password);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/orders');
    }
  }, []);

  return (
    <div
      style={{
        height: '100vh'
      }}
      className="flex justify-center items-center">
      <Spin spinning={isLoading}>
        <div className="flex justify-center">
          <div className="primary-box-shadow max-w-[750px] w-[90%] flex flex-col md:flex-row bg-[#ffffff] rounded-xl">
            <div
              style={{
                background: 'rgba(3, 143, 222, 0.7)'
              }}
              className="w-full md:w-[35%] p-9 pb-5 flex flex-col gap-3.5 text-[#ffffff] rounded-t-xl md:rounded-r-none md:rounded-l-xl">
              <img
                className="mx-auto"
                src={`${CLOUDINARY_URL}/Sahaj/sahaj-powered-coreyo-logo_rwsesz.png`}
                alt="Coreyoo Logo"
                width={150}
                height={50}
              />
              <h1 className="text-3xl m-0 font-bold">Sign In</h1>
              <p className="m-0">By Signing Up, you can avail full features of our services.</p>
              <p className="m-0">Get an account !!!</p>
            </div>
            <div
              style={{
                borderRadius: '0 12px 12px 0'
              }}
              className="w-full md:w-[65%] p-9 pb-5 flex flex-col justify-center gap-2.5">
              <Form
                scrollToFirstError
                id={'signInForm'}
                layout="vertical"
                size={'large'}
                onFinish={onSubmitSignIn}
                initialValues={{ remember_me: false }}>
                <Form.Item
                  className="mb-5"
                  rules={[{ required: true, message: 'Please enter email id.' }]}
                  name="email">
                  <Input placeholder="Email" type="text" />
                </Form.Item>

                <Form.Item
                  className="m-0"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                  name="password">
                  <Input type="password" placeholder="Password" />
                </Form.Item>

                <div className="mt-4 flex gap-3 items-center">
                  <Form.Item className="mb-0">
                    <Button
                      style={{ background: 'rgba(3, 143, 222, 0.7)' }}
                      type="primary"
                      className="mb-0"
                      htmlType="submit">
                      Sign In
                    </Button>
                  </Form.Item>
                  <button
                    onClick={() => navigate('/forget-password')}
                    style={{ color: 'rgba(3, 143, 222, 0.7)' }}
                    className="m-0 text-sm font-bold cursor-pointer">
                    Forget your password?
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default SignIn;
