export const formRegex = {
  name: {
    pattern: new RegExp(/^[a-zA-Z0-9\d\-_@/.,\s]+$/),
    message: 'Only , . / - _ special characters allowed'
  },
  email: {
    pattern: new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/),
    message: 'Please enter valid email'
  },
  mobile: {
    pattern: new RegExp(/^[0-9]{10}$/),
    message: 'Please enter a valid mobile number'
  }
};
