import React, { FC } from 'react';
import UsersTable from './UsersTable';

const Users: FC = () => {
  return (
    <div>
      <UsersTable />
    </div>
  );
};

export default Users;
