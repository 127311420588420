import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'antd';

interface GetOrderStatusProps {
  refundStatus: string;
  setRefundRequestModal?: Dispatch<SetStateAction<boolean>>;
}

const GetOrderStatus: React.FC<GetOrderStatusProps> = ({ refundStatus, setRefundRequestModal }) => {
  const onButtonClick = () => {
    if (setRefundRequestModal) {
      setRefundRequestModal(true);
    }
  };

  return (
    <div className="flex items-center">
      {refundStatus === 'NA' ? (
        <Button className="m-0 primary-button" onClick={onButtonClick}>
          Request
        </Button>
      ) : refundStatus === 'APPROVED' ? (
        'Approved'
      ) : refundStatus === 'INITIATED' ? (
        'Requested'
      ) : refundStatus === 'DECLINED' ? (
        'Declined'
      ) : (
        '---'
      )}
    </div>
  );
};

export default GetOrderStatus;
