import { Button, Form, Input, Modal, Radio, Select, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  ICancelOrderPayload,
  IOrderCancellationResponse
} from '../../../../../../interfaces/cancelOrder';
import { cancelOrderById, orderCancellationStatus } from './api';
import CancellationStatusModal from './CancellationStatusModal';

interface ICancelOrderDiv {
  singleOrderData: any;
  handleGetOrderDetails: () => void;
}

const CancelOrderDiv: FC<ICancelOrderDiv> = ({ singleOrderData, handleGetOrderDetails }) => {
  const [cancellationForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancelReasonModal, setCancelReasonModal] = useState<boolean>(false);
  const [cancelStatusDiv, setCancelStatusDiv] = useState<boolean>(false);
  const [cancelStatusData, setCancelStatusData] = useState<IOrderCancellationResponse>();
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const handleCancelOrder = () => {
    setCancelReasonModal(true);
  };

  useEffect(() => {
    if (cancelStatusDiv) {
      orderCancellationStatus({
        orderId: singleOrderData?.orderId,
        setLoading: setIsStatusLoading,
        setData: setCancelStatusData
      });
    }
  }, [cancelStatusDiv]);

  const handleStatusDetails = () => {
    setCancelStatusDiv(!cancelStatusDiv);
  };

  const onModalClose = (callback: boolean) => {
    setCancelReasonModal(false);
    cancellationForm.resetFields();

    if (callback) {
      handleGetOrderDetails();
    }
  };

  const onCancelOrder = (formValues: ICancelOrderPayload) => {
    const payload = {
      orderId: singleOrderData?.orderId,
      cancellationStatus: formValues?.cancellationStatus,
      cancellationReason: formValues?.cancellationReason
    };

    cancelOrderById({ payload, setLoading: setIsLoading, callBackFunc: () => onModalClose(true) });
  };

  const cancellationTypes = [
    {
      label: 'Cancelled',
      value: 'Cancelled'
    },
    {
      label: 'Cancelled Refunded',
      value: 'Cancelled Refunded'
    }
  ];

  const cancellationReasons = [
    'Demo Order',
    'Shipment Not Ready',
    'Short / Wrong Address',
    'Coreyo Issue',
    'Customer initiated Cancellation',
    'Not able to reach customer',
    'Shipment label not attached'
  ];
  return (
    <>
      <div className="flex items-center">
        <p className="w-44 font-semibold text-xl">Cancel Order</p>
        <p className="pr-2">:</p>
        <Button className="primary-button ml-2" onClick={() => handleCancelOrder()}>
          Cancel
        </Button>
      </div>
      {cancellationTypes
        ?.map((status) => status?.value)
        ?.includes(singleOrderData?.cancellationStatus) && (
        <div className="flex items-center">
          <p className="w-[200px] font-semibold text-xl">Cancellation Status</p>
          <p className="pr-2">:</p>
          <Button
            className="primary-button ml-2"
            loading={isStatusLoading}
            onClick={() => handleStatusDetails()}>
            View Status
          </Button>
        </div>
      )}

      <Modal
        open={cancelReasonModal}
        onCancel={() => onModalClose(false)}
        title={
          <div className="flex items-center gap-2">
            <h2>Cancel Order</h2>
          </div>
        }
        footer={false}>
        <Spin spinning={isLoading}>
          <div className="my-4">
            <Form
              form={cancellationForm}
              layout="vertical"
              id="cancellationForm"
              onFinish={onCancelOrder}>
              <div className="flex items-center mb-4">
                <p className="w-44 font-semibold text-xl">Order ID</p>
                <p className="pr-2">:</p>
                <p>{singleOrderData?.orderId}</p>
              </div>

              <div className="mb-2 flex flex-col">
                <p className="mb-0 mr-2 w-44 flex justify-between font-bold">
                  Cancellation Type <span>:</span>
                </p>
                <Form.Item
                  className="mb-0"
                  name="cancellationStatus"
                  rules={[{ required: true, message: 'Please select any type.' }]}>
                  <Radio.Group>
                    {cancellationTypes?.map((options, key) => (
                      <Radio key={key} value={options?.value}>
                        {options?.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className="flex flex-col gap-2">
                <p className="font-bold">Cancellation Reason :</p>
                <Form.Item
                  name="cancellationReason"
                  rules={[
                    {
                      required: true,
                      message: 'Please select any reason.'
                    }
                  ]}>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select reason"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={cancellationReasons?.map((option) => ({
                      label: option,
                      value: option
                    }))}
                  />
                </Form.Item>
              </div>

              <div className="my-5 flex justify-end gap-5">
                <Button onClick={() => onModalClose(false)}>Go back</Button>
                <Button
                  disabled={isLoading}
                  htmlType="submit"
                  form="cancellationForm"
                  className="px-10 primary-button">
                  Cancel Order
                </Button>
              </div>
            </Form>
          </div>
        </Spin>
      </Modal>

      <CancellationStatusModal
        cancelStatusDiv={cancelStatusDiv}
        setCancelStatusDiv={setCancelStatusDiv}
        cancelStatusData={cancelStatusData}
      />
    </>
  );
};

export default CancelOrderDiv;
