import React from 'react';

interface LabelProps {
  labelText: string;
}
const FormLabel: React.FC<LabelProps> = ({ labelText }) => {
  return <p className="m-0 text-gray-500 font-medium">{labelText}</p>;
};

export default FormLabel;
