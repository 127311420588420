import React, { useEffect, useState } from 'react';

// type
import { ColumnsType } from 'antd/es/table';

import { Button, Spin, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useOrderListDetails from '../../api';
import { IS_REFUND_ENABLED, ROLE_FOR_REFUND_ACTIONS } from '../../../../../utils/config';
import { getTokenDetails } from '../../../../../utils/getTokenDetails';
import { getDate } from '../../../../../utils/getDate';
import GetStatus from '../../../../../utils/GetStatus';
import { getAccessPermission } from '../../../../../utils/getAccessPermission';
import GetOrderStatus from '../Utils/GetOrderStatus';
import CancelOrderDiv from './CancelOrderDiv';
import TrackingDetailsModal from './TrackingDetailsModal';

const OrderDetails: React.FC = () => {
  const navigate = useNavigate();
  const param = useParams();
  const { getDetailsOfOrder, PaymentDetails, singleOrderData, isLoading, getRefundOrdersList } =
    useOrderListDetails();
  const { tokenData } = getTokenDetails();

  const [detailsTableData, setDetailsTableData] = useState<any>([]);
  const [packageTableData, setPackageTableData] = useState([]);
  const [refundRequestModal, setRefundRequestModal] = useState<boolean>(false);
  const [refundDetailsModal, setRefundDetailsModal] = useState<boolean>(false);
  const [refundActionModal, setRefundActionModal] = useState<boolean>(false);
  const [showTrackingDetails, setShowTrackingDetails] = useState<boolean>(false);

  const handleGetOrderDetails = () => {
    getDetailsOfOrder({ orderId: param?.orderId || '' });
  };

  useEffect(() => {
    handleGetOrderDetails();
  }, [param]);

  useEffect(() => {
    const updatedData = Object.keys(singleOrderData)?.length
      ? [
          {
            rowHeading: 'Pickup Details',
            ...singleOrderData?.pickupDetails
          },
          {
            rowHeading: 'Delivery Details',
            ...singleOrderData?.deliveryDetails
          },
          {
            rowHeading: 'Billing Details',
            ...singleOrderData?.billingDetails
          }
        ]
      : [];

    setDetailsTableData(updatedData);

    const updatedPackageData = singleOrderData?.packageDetails?.length
      ? singleOrderData?.packageDetails?.map((item: any, index: number) => ({
          ...item,
          packageNum: index + 1
        }))
      : [];

    setPackageTableData(updatedPackageData);
  }, [singleOrderData]);

  const orderDetailsColumns: ColumnsType<any> = [
    {
      title: '',
      dataIndex: 'rowHeading',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Name',
      dataIndex: 'customerName',
      width: 150,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      width: 200,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      width: 200,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
      width: 100,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Mobile Num',
      dataIndex: 'mobileNumber',
      width: 130,
      render: (data) => (data ? data : 'NA')
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      width: 150,
      render: (data) => (data ? data : 'NA')
    }
  ];

  const packageDetailsColumns: ColumnsType<any> = [
    {
      title: 'Package Num',
      dataIndex: 'packageNum',
      width: 150,
      render: (data) => (data ? <p className="m-0 font-semibold">{data}</p> : '-')
    },
    {
      title: 'Weight (g)',
      dataIndex: 'weight',
      width: 150
    },
    {
      title: 'Length (cm)',
      dataIndex: 'length',
      width: 150
    },
    {
      title: 'Height (cm)',
      dataIndex: 'height',
      width: 150
    },
    {
      title: 'Width (cm)',
      dataIndex: 'width',
      width: 150
    }
  ];

  const handleGetRefundList = async (isAction: boolean) => {
    await getRefundOrdersList({
      callbackFun: () => {
        isAction ? setRefundActionModal(true) : setRefundDetailsModal(true);
      },
      orderId: param?.orderId
    });
  };

  return (
    <>
      <Button className="m-0 p-0" type="link" onClick={() => navigate(-1)}>
        {`<`}
        <span className="hover:underline pl-1">Back</span>
      </Button>
      <h3 className="text-center text-lg p-0 m-0 mb-3">Order Details</h3>
      <Table
        columns={orderDetailsColumns}
        dataSource={detailsTableData}
        pagination={false}
        loading={isLoading}
        scroll={{
          x: 1600
        }}
        rowKey={'rowHeading'}
      />
      <div className="my-10">
        <h3 className="text-center text-lg p-0 m-0 mb-3">Package Details</h3>
        <Table
          columns={packageDetailsColumns}
          dataSource={packageTableData}
          pagination={false}
          loading={isLoading}
          scroll={{
            x: 775
          }}
          rowKey={'packageNum'}
        />
      </div>

      <Spin className="mt-10" spinning={isLoading}>
        <div className="flex gap-6 flex-col xl:flex-row justify-between">
          <div className="w-full xl:w-[49%] primary-box-shadow rounded-xl p-5 flex flex-col gap-3.5">
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Order ID</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.orderId}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">AWB #</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.trackingNumber}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Order Date</p>
              <p className="pr-2">:</p>
              <p>{getDate(singleOrderData?.createdAt)}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Order Status</p>
              <p className="pr-2">:</p>
              <GetStatus
                currStatus={singleOrderData?.orderStatus}
                PROCESSING="Processing"
                SUCCESS="Order Created"
                FAILED="Order Failed"
                CHECKED_OUT="Checked Out"
                CANCELLED="Cancelled"
              />
            </div>

            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Tracking Status</p>
              <p className="pr-2">:</p>
              <div className="flex gap-3 items-center">
                {singleOrderData?.trackingStatus?.length && (
                  <>
                    {typeof singleOrderData?.trackingStatus === 'object' ? (
                      <p className="font-bold text-grey-400 uppercase">
                        {singleOrderData?.trackingStatus?.length &&
                          singleOrderData?.trackingStatus[
                            singleOrderData?.trackingStatus?.length - 1
                          ]?.mainStatus}
                      </p>
                    ) : (
                      <p className="font-bold text-grey-400 uppercase">
                        {singleOrderData?.trackingStatus}
                      </p>
                    )}
                    {typeof singleOrderData?.trackingStatus === 'object' && (
                      <Button
                        className="primary-button"
                        onClick={() => setShowTrackingDetails(true)}>
                        Details
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Pickup Vendor</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.pickupVendor}</p>
            </div>
            {IS_REFUND_ENABLED ? (
              <>
                <div className="flex items-center">
                  <p className="min-w-44 font-semibold text-xl">Refunds</p>
                  <p className="pr-2">:</p>
                  <div>
                    <div className="flex items-center">
                      <GetOrderStatus
                        refundStatus={singleOrderData?.refundStatus?.toUpperCase()}
                        setRefundRequestModal={setRefundRequestModal}
                      />

                      <div className="m-0 ml-2">
                        {singleOrderData?.initiatedCount >= 1 ? (
                          <Button
                            className="primary-button"
                            onClick={() => handleGetRefundList(false)}>
                            Details
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {getAccessPermission({
                  allowedPermissions: ROLE_FOR_REFUND_ACTIONS,
                  userPermissions: tokenData?.role || []
                })?.length && singleOrderData?.initiatedCount >= 1 ? (
                  <div className="flex items-center">
                    <p className="min-w-44 font-semibold text-xl">Refund Request</p>
                    <p className="pr-2">:</p>
                    <div>
                      <Button className="primary-button" onClick={() => handleGetRefundList(true)}>
                        Action
                      </Button>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Bill amount</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.totalAmount}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Payment Provider</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.paymentVendor}</p>
            </div>

            {singleOrderData?.failureReason && (
              <div className="flex items-start">
                <p className="min-w-44 font-semibold text-xl">Failure Reason</p>
                <p className="pr-2">:</p>
                <p>{singleOrderData?.failureReason}</p>
              </div>
            )}

            {tokenData?.userRole === 0 && (
              <CancelOrderDiv
                handleGetOrderDetails={handleGetOrderDetails}
                singleOrderData={singleOrderData}
              />
            )}
          </div>
          <div className="w-full xl:w-[49%] primary-box-shadow rounded-xl p-5 flex flex-col gap-4">
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Delivery Vendor</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.deliveryVendor}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Payment Type</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.paymentType}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Service</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.serviceType}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Item Desc</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.itemDescription}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Item Category</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.itemCategory}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Item Value</p>
              <p className="pr-2">:</p>
              <p>{singleOrderData?.estimatedValue}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Invoice</p>
              <p className="pr-2">:</p>
              <span>
                <Button
                  href={singleOrderData?.invoiceOrder}
                  target="_blank"
                  className="primary-button">
                  View Invoice
                </Button>
              </span>
            </div>
            <div className="flex items-center">
              <p className="min-w-44 font-semibold text-xl">Shipment Label</p>
              <p className="pr-2">:</p>
              <span>
                <Button
                  href={singleOrderData?.shipmentLabel}
                  target="_blank"
                  className="primary-button">
                  View Shipment Label
                </Button>
              </span>
            </div>
          </div>
        </div>
      </Spin>

      <TrackingDetailsModal
        trackingData={singleOrderData?.trackingStatus}
        showTrackingDetails={showTrackingDetails}
        setShowTrackingDetails={setShowTrackingDetails}
      />
    </>
  );
};

export default OrderDetails;
