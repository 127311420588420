import { PaginationProps, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { GetCancellationOrdersList } from './api';
import { ICancellationOrderData, ICancellationOrdersList } from '../../../interfaces/cancelOrder';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const CancellationList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancellationListData, setCancellationListData] = useState<ICancellationOrdersList>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const handleGetOrdersList = () => {
    GetCancellationOrdersList({
      setLoading: setIsLoading,
      setData: setCancellationListData,
      currentPage: currentPage,
      pageLimit: pageLimit
    });
  };

  useEffect(() => {
    handleGetOrdersList();
  }, [currentPage, pageLimit]);

  const tableColumns: ColumnsType<ICancellationOrderData> = [
    {
      title: 'Order Id',
      dataIndex: 'order_id',
      width: 120
    },
    {
      title: 'Cancel Status',
      dataIndex: 'cancel_status',
      width: 150
    },
    {
      title: 'Cancellation Reason',
      dataIndex: 'cancel_reason',
      width: 150
    },
    {
      title: 'Cancelled by',
      dataIndex: 'sahaj_admin',
      width: 120,
      render: (name) => (
        <div>
          {name?.first_name} {name?.last_name}
        </div>
      )
    },
    {
      title: 'Cancellation Date',
      dataIndex: 'created_date',
      width: 150,
      render: (date) => moment(date).format('YYYY-MM-DD, HH:mm:ss')
    }
  ];

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <>
      <h2 className="text-xl font-normal m-0 mb-4">Cancelled Orders List</h2>

      <Table
        scroll={{ x: 1616 }}
        dataSource={cancellationListData?.list}
        columns={tableColumns}
        loading={isLoading}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: cancellationListData?.totalCount
            ? cancellationListData?.totalCount * pageLimit
            : 0,
          onChange: onPageChange
        }}
      />
    </>
  );
};

export default CancellationList;
