import { Divider, Form, Input, Spin } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordChangeFields from '../PasswordChangeFields';
import { IChangePasswordForm, IChangePasswordPayload } from '../../../../../interfaces/auth';
import FormLabel from '../../../../CommonComponents/FormLabel';
import { getTokenDetails } from '../../../../../utils/getTokenDetails';
import { formRegex } from '../../../../../utils/formGlobalRegex';
import handleUserLogin from '../../api';

const ChangePassword: React.FC = () => {
  const { tokenData } = getTokenDetails();
  const navigate = useNavigate();

  const [changePasswordForm] = Form.useForm();

  const { isLoading, userChangePassword } = handleUserLogin();
  const handleChangePassword = (formValues: IChangePasswordForm) => {
    let payload: IChangePasswordPayload = {
      password: formValues?.currentPassword,
      updatedPassword: formValues?.newPassword
    };

    if (tokenData?.userRole === 0) {
      payload = { ...payload, email: formValues?.userEmail };
    }

    userChangePassword({
      payload,
      callBackFunc: () => {
        navigate(-1);
        changePasswordForm.resetFields();
      }
    });
  };

  return (
    <div className="flex justify-center">
      <div className="primary-box-shadow w-[90%] max-w-[500px] p-8 rounded-xl flex flex-col">
        <Spin spinning={isLoading}>
          <Form
            form={changePasswordForm}
            onFinish={handleChangePassword}
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            initialValues={{ remember_me: false }}>
            {tokenData?.userRole === 0 ? (
              <Form.Item
                className="mb-5"
                rules={[{ required: true, message: 'Please enter email id.' }, formRegex?.email]}
                label={<FormLabel labelText="Enter Email Id" />}
                name="userEmail">
                <Input placeholder="Email Id" type="text" />
              </Form.Item>
            ) : null}

            <Form.Item
              className="m-0"
              rules={[{ required: true, message: 'Please enter current password.' }]}
              name="currentPassword"
              label={<FormLabel labelText="Enter Current Password" />}>
              <Input placeholder="Current Password" type="password" />
            </Form.Item>

            <Divider />
            <PasswordChangeFields />
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ChangePassword;
