import React, { FC, useState } from 'react';
import { Button, Form, Input, Switch } from 'antd';
import FormLabel from '../../../../CommonComponents/FormLabel';
import { formRegex } from '../../../../../utils/formGlobalRegex';
import { IAddUser } from '../../../../../interfaces/user';
import ConfirmModal from '../../../../../utils/ConfirmModal';
import { addNewUser } from '../../api';

const CreateAdminForm: FC = () => {
  const initialNewUserData = {
    email: '',
    first_name: '',
    last_name: '',
    mobile: '',
    status: ''
  };
  const [newUserData, setNewUserData] = useState<IAddUser>(initialNewUserData);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addNewUserForm] = Form.useForm<IAddUser>();

  const handleAddNewUser = (formValues: IAddUser) => {
    setNewUserData({
      email: formValues?.email?.trim(),
      first_name: formValues?.first_name?.trim(),
      last_name: formValues?.last_name?.trim(),
      mobile: formValues?.mobile?.trim(),
      status: formValues?.status ? 'active' : 'in-active'
    });
    setConfirmModal(true);
  };

  const onConfirmAdd = () => {
    addNewUser({
      setLoading: setIsLoading,
      payload: newUserData,
      callBackFun: () => {
        onCancelConfirm();
      }
    });
  };

  const onCancelConfirm = () => {
    setConfirmModal(false);
    addNewUserForm.resetFields();
    setNewUserData(initialNewUserData);
  };
  return (
    <>
      <div className="flex justify-center">
        <div className="primary-box-shadow w-[90%] max-w-[500px] p-5 rounded-xl flex flex-col">
          <h2 className="text-xl text-center font-normal m-0 pb-3 mb-3 border-b-2">Add Admin</h2>

          <Form
            form={addNewUserForm}
            onFinish={handleAddNewUser}
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            id="addUserForm">
            <div className="flex gap-5">
              <Form.Item
                className="mb-5"
                rules={[{ required: true, message: 'Please enter first name.' }, formRegex?.name]}
                label={<FormLabel labelText="First Name" />}
                name="first_name">
                <Input placeholder="First Name" type="text" />
              </Form.Item>

              <Form.Item
                className="mb-5"
                rules={[{ required: true, message: 'Please enter last name.' }, formRegex?.name]}
                label={<FormLabel labelText="Last Name" />}
                name="last_name">
                <Input placeholder="Last Name" type="text" />
              </Form.Item>
            </div>

            <Form.Item
              className="mb-5"
              rules={[{ required: true, message: 'Please enter email id.' }, formRegex?.email]}
              label={<FormLabel labelText="Enter Email Id" />}
              name="email">
              <Input placeholder="Email Id" type="text" />
            </Form.Item>

            <Form.Item
              className="mb-5"
              rules={[
                { required: true, message: 'Please enter mobile number.' },
                formRegex?.mobile
              ]}
              label={<FormLabel labelText="Mobile Number" />}
              name="mobile">
              <Input placeholder="Mobile Number" type="text" />
            </Form.Item>

            <div className="flex gap-5 items-center">
              <FormLabel labelText="Status:" />
              <Form.Item className="flex-row m-0" name="status" initialValue={true}>
                <Switch defaultChecked className="bg-gray-300" />
              </Form.Item>
            </div>

            <div className="mt-5 flex justify-center">
              <Button htmlType="submit" form="addUserForm" className="px-10 primary-button">
                Add
              </Button>
            </div>
          </Form>
        </div>
      </div>

      <ConfirmModal
        isLoading={isLoading}
        isModalOpen={confirmModal}
        onClickOk={onConfirmAdd}
        onCancel={onCancelConfirm}
        modalBody={
          <div className="flex flex-col gap-3">
            <p>Confirm to add following user:</p>

            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Email <span>:</span>
              </p>
              <p>{newUserData?.email}</p>
            </div>
            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Name <span>:</span>
              </p>
              <p>
                {newUserData?.first_name} {newUserData?.last_name}
              </p>
            </div>

            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Mobile <span>:</span>
              </p>
              <p>{newUserData?.mobile}</p>
            </div>

            <div className="font-bold flex gap-3">
              <p className="w-[100px] flex justify-between">
                Status <span>:</span>
              </p>
              <p>{newUserData?.status}</p>
            </div>
          </div>
        }
      />
    </>
  );
};

export default CreateAdminForm;
