export const API_KEY = process.env.REACT_APP_API_KEY;
export const APP_ID = process.env.REACT_APP_APP_ID;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const FOOTER_TEXT = process.env.REACT_APP_FOOTER_TEXT;
export const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;
export const ROLE_FOR_REFUND_ACTIONS = JSON.parse(
  process.env.REACT_APP_ROLE_FOR_REFUND_ACTIONS || '[]'
);
export const APP_TOKEN = process.env.REACT_APP_TOKEN;
export const MAX_ORDERS_ALLOWED = process.env.REACT_APP_MAX_ORDERS_ALLOWED;
export const ADDRESS_LENGTH = process.env.REACT_APP_ADDRESS_LENGTH;
export const NAME_LENGTH = process.env.REACT_APP_NAME_LENGTH;
export const API_URL = process.env.REACT_APP_API_URL;
export const IS_REFUND_ENABLED = JSON.parse(process.env.REACT_APP_IS_REFUND_ENABLED || 'true');
export const IS_PUSH_FAILED_ORDER_ENABLED = JSON.parse(
  process.env.REACT_APP_IS_PUSH_FAILED_ORDER_ENABLED || 'false'
);
