import React, { Dispatch, SetStateAction } from 'react';
import { Form, Select } from 'antd';
import { DELIVERY_VENDORS_LIST } from '../../../../utils/constant';

interface Props {
  setDeliveryVendor: Dispatch<SetStateAction<string>>;
}

const DeliveryVendor: React.FC<Props> = ({ setDeliveryVendor }) => {
  const handleVendorChange = (value: string) => {
    setDeliveryVendor(value);
  };

  return (
    <div className="mb-5 flex items-center">
      <p className="m-0 mr-2 w-[30%] flex justify-between">
        Delivery Vendor <span>:</span>
      </p>
      <div className="w-[70%]">
        <Form.Item name="deliveryVendor" className="m-0 w-full">
          <Select
            showSearch
            allowClear
            placeholder="Select Vendor"
            onChange={handleVendorChange}
            options={DELIVERY_VENDORS_LIST?.map((item: string) => ({ value: item, label: item }))}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default DeliveryVendor;
