import React, { FC } from 'react';
import CreateAdminForm from './CreateAdminForm';

const AddUser: FC = () => {
  return (
    <div>
      <CreateAdminForm />
    </div>
  );
};

export default AddUser;
