import dayjs, { Dayjs } from 'dayjs';

export const PROVIDER_NAMES = {
  INDIA_POST: 'India Post',
  ECOM: 'Ecom Express',
  FEDEX: 'Fedex',
  DELHIVERY: 'Delhivery',
  DELHIVERY_INTERNATIONAL: 'Delhivery International',
  CRITICALOG: 'CriticaLog',
  SHREEMARUTI: 'Shree Maruti',
  // SHREEMARUTI2: 'ShreeMaruti',
  SHADOWFAX: 'ShadowFax',
  XPRESSBEES: 'XpressBees',
  AMAZON: 'Amazon',
  QWQER: 'Qwqer',
  DUNZO: 'Dunzo',
  SELF_DROP: 'Self Drop!',
  GATI: 'Gati',
  DTDC: 'DTDC',
  BLUEDART: 'Blue Dart',
  SMARTR: 'Smartr',
  DTDC_INTERNATIONAL: 'Dtdc International',
  MOVIN: 'Movin',
  EKART: 'Ekart'
};

export const DELIVERY_VENDORS_LIST = [
  'Delhivery',
  'ShadowFax',
  'Ecom Express',
  'CriticaLog',
  'DTDC',
  'Shree Maruti',
  'XpressBees',
  'Delhivery International',
  'Gati',
  'Blue Dart',
  'India Post',
  'Smartr',
  'Amazon',
  'Coreyo',
  'IndiaPost',
  'Dtdc international',
  'Movin',
  'Ekart'
];

export const DateRangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
  { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
  { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] }
];

export const UPDATE_ORDERS_FIELDS: { label: string; value: string }[] = [
  {
    value: 'billing_address_line1',
    label: 'Billing Address Line 1'
  },
  { value: 'billing_address_line2', label: 'Billing Address Line 2' },
  {
    value: 'billing_address_city',
    label: 'Billing Address City'
  },
  {
    value: 'billing_address_pincode',
    label: 'Billing Address Pincode'
  },
  {
    value: 'billing_address_country',
    label: 'Billing Address Country'
  },
  {
    value: 'billing_email',
    label: 'Billing Email'
  },
  {
    value: 'billing_customer_name',
    label: 'Billing Customer Name'
  },
  {
    value: 'delivery_address_line1',
    label: 'Delivery Address Line 1'
  },
  {
    value: 'delivery_address_line2',
    label: 'Delivery Address Line 2'
  },
  {
    value: 'delivery_address_city',
    label: 'Delivery Address City'
  },
  {
    value: 'delivery_address_pincode',
    label: 'Delivery Address Pincode'
  },
  {
    value: 'delivery_address_country',
    label: 'Delivery Address Country'
  },
  {
    value: 'delivery_email',
    label: 'Delivery Email'
  },
  {
    value: 'pickup_address_line1',
    label: 'Pickup Address Line 1'
  },
  {
    value: 'pickup_address_line2',
    label: 'Pickup Address Line 2 '
  },
  {
    value: 'pickup_address_city',
    label: 'Pickup Address City'
  },
  {
    value: 'pickup_address_pincode',
    label: 'Pickup Address Pincode'
  },
  { value: 'pickup_address_country', label: 'Pickup Address Country' },
  { value: 'pickup_email', label: 'Pickup Email' },
  { value: 'pickup_customer_name', label: 'Pickup Customer Name' },
  {
    value: 'weight',
    label: 'Weight'
  },
  {
    value: 'shipment_length',
    label: 'Shipment Length'
  },
  {
    value: 'shipment_height',
    label: 'Shipment Height'
  },
  {
    value: 'shipment_width',
    label: 'Shipment Width'
  },
  {
    value: 'estimated_value',
    label: 'Estimated Value'
  },
  {
    value: 'item_description',
    label: 'Item Description'
  },
  { value: 'item_category', label: 'Item Category' },
  {
    value: 'service_type',
    label: 'Service Type'
  },
  {
    value: 'delivery_phone_number',
    label: 'Delivery Phone Number'
  },
  {
    value: 'delivery_customer_name',
    label: 'Delivery Customer Name'
  },
  {
    value: 'delivery_address_state',
    label: 'Delivery Address State'
  },
  {
    value: 'pickup_address_state',
    label: 'Pickup Address State'
  },
  {
    value: 'pickup_phone_number',
    label: 'Pickup Phone Number'
  },
  {
    value: 'billing_address_state',
    label: 'Billing Address State'
  },
  {
    value: 'billing_phone_number',
    label: 'Billing Phone Number'
  },
  {
    value: 'delivery_vendor',
    label: 'Delivery Vendor'
  },
  {
    value: 'price',
    label: 'Price'
  },
  {
    value: 'special_field',
    label: 'Special Field'
  }
];
