import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import YellowCircle from '../../YellowCircle';

interface Props {
  orderId: string;
  setOrderId: Dispatch<SetStateAction<string>>;
  // eslint-disable-next-line
  formInstance: FormInstance<any>;
  formId: string;
  title?: string;
  placeHolder?: string;
}
const GetOrderByIdFilter = ({
  orderId,
  setOrderId,
  formInstance,
  title = 'Order ID',
  placeHolder = 'Order ID',
  formId = 'orderIdForm'
}: Props) => {
  const handleOrderIdSearch = ({ orderId }: { orderId: string }) => {
    setOrderId(orderId);
  };

  const handleOrderIdReset = () => {
    setOrderId('');
    formInstance.resetFields();
  };

  return {
    title: (
      <div className="flex items-center">
        {title}
        {orderId ? <YellowCircle /> : null}
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filterDropdown: () => (
      <div className="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Form form={formInstance} layout="vertical" id={formId} onFinish={handleOrderIdSearch}>
          <Form.Item name="orderId" className="mb-3">
            <Input placeholder={placeHolder} value={orderId} />
          </Form.Item>
        </Form>
        <div className="flex gap-3 justify-end">
          <Button onClick={handleOrderIdReset} size="small">
            Reset
          </Button>
          <Button htmlType="submit" form={formId} size="small" className="primary-button">
            Search
          </Button>
        </div>
      </div>
    )
  };
};

export default GetOrderByIdFilter;
