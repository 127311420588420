import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import { showMessage } from '../../../../utils/ResponseMessages';
import { ICancellationOrdersList } from '../../../../interfaces/cancelOrder';

export const GetCancellationOrdersList = async ({
  setLoading,
  setData,
  currentPage,
  pageLimit
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<ICancellationOrdersList | undefined>>;
  currentPage: number;
  pageLimit: number;
}) => {
  setLoading(true);

  const offset = currentPage - 1;
  try {
    const response = await httpClient.get(
      `/order-cancellation/list?limit=${pageLimit}&offset=${offset}`
    );
    setData(response?.data?.data);
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
    setData(undefined);
  } finally {
    setLoading(false);
  }
};
